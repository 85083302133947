import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import "antd/dist/antd.less";
import "./assets/scss/custom.scss";

import { SANCTUM_COOKIE_URL } from "./utils/constants/apiRoutes";

import Axios from "axios";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";

import "./utils/constants/atoms";

import { RecoilRoot } from "recoil";

Axios.defaults.withCredentials = true;

Axios.get(SANCTUM_COOKIE_URL).then(async (res) => {});

Sentry.init({
  dsn: "https://83442281ab684a7a9644096e8473e987@o476732.ingest.sentry.io/5516793",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0,
});

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Suspense fallback={() => <h1>Loading</h1>}>
        <App />
      </Suspense>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
