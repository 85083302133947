import React, { useState, Suspense } from "react";
import { Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { adminMenus } from "../../utils/menu/admin";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfoState } from "Atoms";
import { PoweroffOutlined } from "@ant-design/icons";
import { logoutUser } from "ApiMethods";
import { LoadingOutlined } from "@ant-design/icons";


const { Header, Sider, Content } = Layout;

export default function DashboardLayout() {
  const [collapsed, setCollapsed] = useState(false);

  const userInfo = useRecoilValue(userInfoState);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const logout = async () => {
    let response = await logoutUser();

    if (response.status) {
      window.location.reload(false);
    }
  };

  return (
    <Router>
      <Layout>
        <Sider
          reverseArrow={true}
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          <div className="logo" />
          <Menu theme="light" mode="inline" defaultSelectedKeys={["0"]}>
            {adminMenus.map((menu, index) => {
              if (menu.insidebar && menu.type.includes(userInfo.type)) {
                return (
                  <Menu.Item key={index} icon={menu.icon}>
                    <Link to={menu.path} className="menu-link">
                      {menu.title}
                    </Link>
                  </Menu.Item>
                );
              }
              return null;
            })}

            <Menu.Item key="logout-user" icon={<PoweroffOutlined />}>
              <Link className="menu-link" to="/" onClick={logout}>
                Logout
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-header" style={{ padding: "0 10px" }}>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: toggle,
              }
            )}
          </Header>
          <Content className="site-layout-background">
            <Switch>
              {adminMenus.map((menu, index) => {
                
                if (menu.type.includes(userInfo.type)) {
                  return (
                    <Route
                      exact
                      path={menu.path}
                      render={(props) => (
                        <Suspense
                          fallback={
                            <div className="loader">
                              <LoadingOutlined style={{ fontSize: 45 }} spin />
                            </div>
                          }
                        >
                          <menu.component {...props}/>
                        </Suspense>
                      )}
                      key={menu.path}
                    />
                  );
                }else {
                  return null;
                }
              })}
                
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
}
