const HOST = process.env.REACT_APP_API_HOST;

const API_URL = HOST + "api/";

export const SANCTUM_COOKIE_URL = HOST + "sanctum/csrf-cookie";

export const AUTH_CHECK = API_URL + "auth/me";

export const LOGIN_URL = HOST + "login";

export const CREATE_JOB = API_URL + "create/job";

export const GET_JOB = API_URL + "get/all/jobs";

export const GET_SINGLE_JOB = API_URL + "get/single/job";

export const UPDATE_JOB = API_URL + "update/job";

export const DELETE_JOB = API_URL + "delete/job";

export const CREATE_EDITOR = API_URL + "create/editor";

export const UPDATE_EDITOR = API_URL + "update/editor";

export const UPDATE_PROFILE = API_URL + "update/user";

export const DELETE_EDITOR = API_URL + "editor/delete";

export const GET_SINGLE_EDITOR = API_URL + "get/single/editor";

export const CHANGE_EDITOR_PASSWORD = API_URL + "change/editor/password";

export const GET_ALL_EDITORS = API_URL + "get/all/editors";

export const CREATE_WORK_ORDER = API_URL + "create/workorder";

export const UPDATE_WORK_ORDER = API_URL + "update/workorder";

export const GET_SINGLE_WORK_ORDER = API_URL + "get/single/workorder";

export const UPLOAD_WORKORDER_IMAGES = API_URL + "upload/workorder/images";

export const UPLOAD_WORKORDER_ATTACHMENT = API_URL + "upload/workorder/attachments";

export const GET_WORKORDER_ATTACHMENT = API_URL + "get/workorder/attachments";

export const DELETE_WORKORDER_ATTACHMENT = API_URL + "delete/workorder/attachments";

export const RESUBMIT_WORKORDER_IMAGES = API_URL + "resubmit/workorder/images";

export const SEND_WORKORDER_IMAGES_SUBMITTED_MAIL =
  API_URL + "submit/images/mail";

export const UPLOAD_WORKORDER_IMAGES_RESULTED =
  API_URL + "upload/workorder/images/resulted";

export const GET_SIGNED_URL_WORKORDER = API_URL + "surl";

export const GET_SIGNED_URL_PROFILE = API_URL + "get/user/signed/url";

export const UPLOAD_PROFILE_PIC = API_URL + "upload/profile/pic";

export const DELETE_WORKORDER_IMAGE = API_URL + "delete/workorder/image";

export const GET_ALL_WORK_ORDERS = API_URL + "all/workorders";

export const GET_JOBS_TO_DOWNLOAD = API_URL + "all/jobs/to/download";

export const GET_JOBS_TO_ASSIGN = API_URL + "all/jobs/to/assign";

export const GET_JOBS_IN_PROGRESS = API_URL + "all/jobs/in/progress";

export const GET_EDITOR_JOBS_IN_PROGRESS = API_URL + "editor/jobs/in/progress";

export const GET_JOBS_IN_ACCEPTED = API_URL + "all/jobs/in/accepted";

export const GET_ALL_WORK_ORDERS_BY_EDITORS = API_URL + "all/workorders/by/editor";

export const GET_MY_WORK_ORDERS = API_URL + "my/workorders";

export const GET_EMPLOYEE_WORK_ORDERS = API_URL + "employee/workorders";

export const REJECT_WORK_ORDER = API_URL + "reject/workorder";

export const ACCEPT_WORK_ORDER = API_URL + "accept/workorder";

export const DELETE_WORK_ORDER = API_URL + "delete/workorder";

export const MAKE_IN_PROGRESS_WORK_ORDER = API_URL + "makeinprogress/workorder";

export const SET_WORK_ORDER_IMAGE_COUNT = API_URL + "set/workorder/imagecount";

export const GET_EDITOR_REPORT = API_URL + "get/editor/report";

export const SUBMIT_REJECTED_PHOTOS = API_URL + "submit/rejected/photos";

export const SUBMIT_REJECTED_PHOTOS_ATTACHMENT = API_URL + "submit/rejected/photos/attachment";

export const GET_REJECTED_FILES = API_URL + "get/rejected/files";

export const GET_REJECTED_FILES_LATEST = API_URL + "get/rejected/files/latest";

export const CREATE_EMPLOYEE = API_URL + "create/employee";

export const GET_ALL_EMPLOYEE = API_URL + "get/all/employees";

export const GET_SINGLE_EMPLOYEE = API_URL + "get/single/employee";

export const UPDATE_EMPLOYEE = API_URL + "update/employee"

export const LOGOUT = HOST + "logout";
