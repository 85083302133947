import Axios from "axios";
import * as Routes from "../constants/apiRoutes";
import { notification } from "antd";

export async function checkLogin() {
  let isAuthenticated = await Axios.get(Routes.AUTH_CHECK)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      let data = error.response.status;

      if (data === 401) {
        return { status: false, message: "Unauthenticated" };
      } else {
        return { status: false, message: "" };
      }
    });

  return isAuthenticated;
}

export async function doLogin(email, password) {
  try {
    let response = await Axios.post(Routes.LOGIN_URL, {
      email,
      password,
    });
    let data = response.data;
    if (data.status) {
      return { status: true, message: "Authenticated", user: data.user };
    }
  } catch (error) {
    if (error.response.status === 422) {
      displayErrorMessages(error);
      return { status: false, message: "UnAuthenticated" };
    }
    return { status: false, message: "UnAuthenticated" };
  }
}

// export function getUser() {
//   Axios.get("http://localhost:8000/api/user").then((res) => {});
// }

export function getImagePath(key, size = null) {
  if (size) {
    return process.env.REACT_APP_IMAGE_CLOUD_FRONT + `/fit-in/${size}/` + key;
  }
  return process.env.REACT_APP_IMAGE_CLOUD_FRONT + "/" + key;
}

export function getImagePathForDownload(key) {
  return process.env.REACT_APP_IMAGE_DRIVE + "/" + key;
}

function findIndexWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

function removeItemAtIndex(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function removeImageAtId(arr, id) {
  return removeItemAtIndex(arr, findIndexWithAttr(arr, "id", id));
}



function displayErrorMessages(error) {
  const errorMessages = error.response.data.errors;

  for (let key in errorMessages) {
    if (errorMessages.hasOwnProperty(key)) {
      notification.error({
        message: "Error",
        description: errorMessages[key],
      });
    }
  }
}

export function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}
