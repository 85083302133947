import React, { useEffect } from "react";

import Login from "./containers/Login";
import { useRecoilState, useSetRecoilState } from "recoil";
import { checkLogin } from "./utils/methods/universal";
import { isLogedIn, userInfoState } from "./utils/constants/atoms";

import Dashboard from "./containers/DashboardLayout";

function App() {
  const [isLogIn, setisLogIn] = useRecoilState(isLogedIn);
  const setUser = useSetRecoilState(userInfoState);

  useEffect(() => {
    async function fetchloginState() {
      let authCheck = await checkLogin();

      if (authCheck.status) {
        setUser(authCheck.user);
        setisLogIn(authCheck.status);
      } else {
        setisLogIn(authCheck.status);
      }
    }

    fetchloginState();
  }, [setisLogIn, setUser]);
  return <div>{isLogIn ? <Dashboard /> : <Login />}</div>;
}

export default App;
