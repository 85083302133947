import * as ApiRoutes from "ApiRoutes";
import Axios from "axios";
import { notification } from "antd";

export async function getSitejobs() {
  try {
    const response = await Axios.get(ApiRoutes.GET_JOB);
    return response.data;
  } catch (error) {
    return { status: false, message: "" };
  }
}

export async function getSingleSitejobs(id) {
  try {
    const response = await Axios.post(ApiRoutes.GET_SINGLE_JOB, {
      id,
    });
    return response.data;
  } catch (error) {
    return { status: false, message: "" };
  }
}

export async function updateSiteJob({ name, uploaded, resulted, id }) {
  try {
    const response = await Axios.post(ApiRoutes.UPDATE_JOB, {
      name,
      uploaded,
      resulted,
      id,
    });
    return response.data;
  } catch (error) {
    return { status: false, message: "" };
  }
}

export async function deleteSiteJob(id) {
  try {
    const response = await Axios.post(ApiRoutes.DELETE_JOB, {
      id: id,
    });
    return response.data;
  } catch (error) {
    return { status: false, message: "" };
  }
}

export async function createEditor(state, jobs) {
  try {
    const response = await Axios.post(ApiRoutes.CREATE_EDITOR, {
      ...state,
      jobs,
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      displayErrorMessages(error);
    }
    return { status: false, message: "Unable to create editor" };
  }
}

export async function updateEditor(state, jobs, id) {
  try {
    const response = await Axios.post(ApiRoutes.UPDATE_EDITOR, {
      ...state,
      jobs,
      id: id,
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      displayErrorMessages(error);
    }
    return { status: false, message: "Unable to create editor" };
  }
}

export async function updateProfile(state, id) {
  try {
    const response = await Axios.post(ApiRoutes.UPDATE_PROFILE, {
      ...state,
      id: id,
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      displayErrorMessages(error);
    }
    return { status: false, message: "Unable to create editor" };
  }
}

export async function deleteUser(id) {
  try {
    const response = await Axios.post(ApiRoutes.DELETE_EDITOR, {
      id: id,
    });
    return response.data;
  } catch (error) {
    return { status: false, message: "" };
  }
}

export async function getAllEditors() {
  try {
    const response = await Axios.get(ApiRoutes.GET_ALL_EDITORS);
    return response.data;
  } catch (error) {
    return { status: false, message: "" };
  }
}

export async function getSingleEditor(id) {
  try {
    const response = await Axios.post(ApiRoutes.GET_SINGLE_EDITOR, {
      id: id,
    });
    return response.data;
  } catch (error) {
    return { status: false, message: "" };
  }
}

export async function changeUserPassword(state, id) {
  try {
    const response = await Axios.post(ApiRoutes.CHANGE_EDITOR_PASSWORD, {
      ...state,
      id,
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      displayErrorMessages(error);
    }
    return { status: false, message: "Unable to create editor" };
  }
}

export async function createWorkOrder(state) {
  try {
    const response = await Axios.post(ApiRoutes.CREATE_WORK_ORDER, {
      ...state,
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      displayErrorMessages(error);
      return { status: false, message: "Unable to create workorder" };
    } else {
      return { status: false, message: "Unable to create workorder" };
    }
  }
}

export async function deleteWorkOrder(slug) {
  try {
    const response = await Axios.post(ApiRoutes.DELETE_WORK_ORDER, {
      slug,
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      displayErrorMessages(error);
      return { status: false, message: "Unable to update workorder" };
    } else {
      return { status: false, message: "Unable to delete workorder" };
    }
  }
}

export async function setWorkOrderImageCount(slug, imgCount) {
  try {
    const response = await Axios.post(ApiRoutes.SET_WORK_ORDER_IMAGE_COUNT, {
      slug,
      image_count: imgCount,
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      displayErrorMessages(error);
      return { status: false, message: "Unable to set Image Count" };
    } else {
      return { status: false, message: "Unable to set Image Count" };
    }
  }
}

export async function updateWorkOrder(state) {
  try {
    const response = await Axios.post(ApiRoutes.UPDATE_WORK_ORDER, {
      ...state,
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      displayErrorMessages(error);
      return { status: false, message: "Unable to update workorder" };
    } else {
      return { status: false, message: "Unable to update workorder" };
    }
  }
}

export async function getSingleWorkOrder(slug) {
  try {
    const response = await Axios.post(ApiRoutes.GET_SINGLE_WORK_ORDER, {
      slug,
    });

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to get Workorder" };
  }
}

export async function uploadWorkOrderImages(
  image,
  slug,
  userType,
  fileType,
  name
) {
  try {
    const response = await Axios.post(ApiRoutes.UPLOAD_WORKORDER_IMAGES, {
      image,
      slug,
      user_type: userType,
      file_type: fileType,
      name,
    });

    return response.data;
  } catch (error) {}
}

export async function uploadWorkOrderAttachment(image, slug) {
  try {
    const response = await Axios.post(ApiRoutes.UPLOAD_WORKORDER_ATTACHMENT, {
      image,
      slug,
    });

    return response.data;
  } catch (error) {}
}

export async function getWorkOrderAttachment(id) {
  try {
    const response = await Axios.post(ApiRoutes.GET_WORKORDER_ATTACHMENT, {
      id,
    });

    return response.data;
  } catch (error) {}
}

export async function resubmitWorkOrderImages(image, slug, fileType, rej_id) {
  try {
    const response = await Axios.post(ApiRoutes.RESUBMIT_WORKORDER_IMAGES, {
      image,
      slug,

      file_type: fileType,
      rej_id,
    });

    return response.data;
  } catch (error) {}
}

export async function imagesSubmittedMail(slug) {
  try {
    const response = await Axios.post(
      ApiRoutes.SEND_WORKORDER_IMAGES_SUBMITTED_MAIL,
      {
        slug,
      }
    );

    return response.data;
  } catch (error) {}
}

export async function rejectWorkORder(slug) {
  try {
    const response = await Axios.post(ApiRoutes.REJECT_WORK_ORDER, {
      slug,
    });

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to reject" };
  }
}

export async function acceptWorkORder(slug) {
  try {
    const response = await Axios.post(ApiRoutes.ACCEPT_WORK_ORDER, {
      slug,
    });

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to reject" };
  }
}

export async function makeInProgressWorkORder(slug) {
  try {
    const response = await Axios.post(ApiRoutes.MAKE_IN_PROGRESS_WORK_ORDER, {
      slug,
    });

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to reject" };
  }
}

export async function deleteWorkorderImage(id) {
  try {
    const response = await Axios.post(ApiRoutes.DELETE_WORKORDER_IMAGE, {
      id: id,
    });

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to delete image" };
  }
}
export async function deleteWorkorderAttachment(id) {
  try {
    const response = await Axios.post(ApiRoutes.DELETE_WORKORDER_ATTACHMENT, {
      id: id,
    });

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to delete image" };
  }
}

export async function getALlworkOrders() {
  try {
    const response = await Axios.get(ApiRoutes.GET_ALL_WORK_ORDERS);

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to get Workorders" };
  }
}

export async function getJobsToDownload(page, pageSize) {
  try {
    const response = await Axios.post(ApiRoutes.GET_JOBS_TO_DOWNLOAD, {
      page,
      pageSize,
    });

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to get Workorders" };
  }
}

export async function getJobsToAssign(page, pageSize) {
  try {
    const response = await Axios.post(ApiRoutes.GET_JOBS_TO_ASSIGN, {
      page,
      pageSize,
    });

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to get Workorders" };
  }
}

export async function getJobsInProgress(page, pageSize) {
  try {
    const response = await Axios.post(ApiRoutes.GET_JOBS_IN_PROGRESS, {
      page,
      pageSize,
    });

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to get Workorders" };
  }
}

export async function getEditorJobsInProgress(page, pageSize) {
  try {
    const response = await Axios.post(ApiRoutes.GET_EDITOR_JOBS_IN_PROGRESS, {
      page,
      pageSize,
    });

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to get Workorders" };
  }
}

export async function getJobsInACCEPTED(page, pageSize) {
  try {
    const response = await Axios.post(ApiRoutes.GET_JOBS_IN_ACCEPTED, {
      page,
      pageSize,
    });

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to get Workorders" };
  }
}



export async function getALlworkOrdersByEditors(date) {
  try {
    const response = await Axios.post(
      ApiRoutes.GET_ALL_WORK_ORDERS_BY_EDITORS,
      {
        date,
      }
    );

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to get Workorders" };
  }
}

export async function getMyworkOrders(page,pageSize) {
  try {
    const response = await Axios.post(ApiRoutes.GET_MY_WORK_ORDERS,{
      page,
      pageSize
    });

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to get Workorders" };
  }
}

export async function getEmployeeworkOrders() {
  try {
    const response = await Axios.get(ApiRoutes.GET_EMPLOYEE_WORK_ORDERS);

    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to get Workorders" };
  }
}

export async function getEditorReport(id, startDate, endDate) {
  try {
    let response = await Axios.post(ApiRoutes.GET_EDITOR_REPORT, {
      id: id,
      start_date: startDate,
      end_date: endDate,
    });

    return response.data;
  } catch (error) {}
}

export async function submitRejectedPhotos(images, reason) {
  try {
    let response = await Axios.post(ApiRoutes.SUBMIT_REJECTED_PHOTOS, {
      images: images,
      work_order_id: images[0].work_order_id,
      reason: reason,
    });

    return response.data;
  } catch (error) {}
}

export async function submitRejectedPhotosAttachment(image, id) {
  try {
    let response = await Axios.post(
      ApiRoutes.SUBMIT_REJECTED_PHOTOS_ATTACHMENT,
      {
        image: image,
        id: id,
      }
    );

    return response.data;
  } catch (error) {}
}

export async function getRejectedPhotos(id) {
  try {
    let response = await Axios.post(ApiRoutes.GET_REJECTED_FILES, {
      id: id,
    });

    return response.data;
  } catch (error) {}
}

export async function getRejectedPhotosLatest(id) {
  try {
    let response = await Axios.post(ApiRoutes.GET_REJECTED_FILES_LATEST, {
      id: id,
    });

    return response.data;
  } catch (error) {}
}

export async function createEmployee(state) {
  try {
    const response = await Axios.post(ApiRoutes.CREATE_EMPLOYEE, {
      ...state,
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      displayErrorMessages(error);
    }
    return { status: false, message: "Unable to create editor" };
  }
}

export async function getAllEmployees() {
  try {
    const response = await Axios.get(ApiRoutes.GET_ALL_EMPLOYEE);
    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to get employees" };
  }
}

export async function getSingleEmployee(id) {
  try {
    const response = await Axios.post(ApiRoutes.GET_SINGLE_EMPLOYEE, {
      id: id,
    });
    return response.data;
  } catch (error) {
    return { status: false, message: "Unable to get employee" };
  }
}

export async function updateEmployee(state, id) {
  try {
    const response = await Axios.post(ApiRoutes.UPDATE_EMPLOYEE, {
      ...state,
      id: id,
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      displayErrorMessages(error);
    }
    return { status: false, message: "Unable to create editor" };
  }
}

export async function logoutUser(id) {
  try {
    let response = await Axios.get(ApiRoutes.LOGOUT);
    return response.data;
  } catch (error) {}
}

function displayErrorMessages(error) {
  const errorMessages = error.response.data.errors;

  for (let key in errorMessages) {
    if (errorMessages.hasOwnProperty(key)) {
      notification.error({
        message: "Error",
        description: errorMessages[key],
      });
    }
  }
}
