const { atom, selector } = require("recoil");

export const isLogedIn = atom({
  key: "isLogedIn", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const userInfoState = atom({
  key: "userInfoState",
  default: {
    first_name: "",
    created_at: "",
  },
});

// ********************************************************************
// for all work orders on dashboard

export const workOrderState = atom({
  key: "workOrderState",
  default: [],
});

export const acceptedWorkOrdersState = selector({
  key: "acceptedWorkOrdersState",
  get: ({ get }) => {
    const orders = get(workOrderState);

    return orders.filter((order) => order.accepted === 1);
  },
});

export const completedWorkOrdersState = selector({
  key: "completedWorkOrdersState",
  get: ({ get }) => {
    const orders = get(workOrderState);

    return orders.filter(
      (order) =>
        order.expected_result.expected === order.expected_result.result &&
        order.expected_result.uploaded !== 0 && order.accepted === 0
    );
  },
});

export const rejectedWorkOrdersState = selector({
  key: "rejectedWorkOrdersState",
  get: ({ get }) => {
    const orders = get(workOrderState);

    return orders.filter((order) => order.rejected === 1);
  },
});

export const inprogressWorkOrdersState = selector({
  key: "inprogressWorkOrdersState",
  get: ({ get }) => {
    const orders = get(workOrderState);

    return orders.filter(
      (order) =>
        order.accepted === 0 &&
        order.rejected === 0 &&
        order.user_id !== null 
    );
  },
});

export const workOrderWithoutEditors = selector({
  key: "workOrderWithoutEditors",
  get: ({ get }) => {
    const orders = get(workOrderState);

    return orders.filter((order) => order.user_id === null);
  },
});

// ********************************************************************

export const singleWorkOrderState = atom({
  key: "singleWorkOrderState",
  default: {},
});

export const workOrderImagesState = atom({
  key: "workorderImages",
  default: [],
});

export const workOrderAttachmentState = atom({
  key: "workOrderAttachmentState",
  default: [],
});

export const resultedImageState = selector({
  key: "resultedImageState",
  get: ({ get }) => {
    const images = get(workOrderImagesState);

    return images.filter(
      (image) => image.type === "IMAGE" && image.uploaded === 0
    );
  },
});

export const reportInfoState = atom({
  key: "reportInfoState",
  default: [],
});

export const rejectedImagesState = atom({
  key: "rejectedImagesState",
  default: [],
});
