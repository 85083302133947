import React from "react";
import * as AntdIcons from "@ant-design/icons";

// Components
const Dashboard = React.lazy(() => import("../../containers/Admin/Dashboard"));

const AddEditor = React.lazy(() =>
  import("../../containers/Admin/Editor/AddEditor")
);
const Jobs = React.lazy(() => import("../../containers/Admin/Jobs"));
const AddJobs = React.lazy(() => import("../../containers/Admin/Jobs/AddJobs"));
const SingleWorkOrder = React.lazy(() =>
  import("../../containers/Admin/WorkOrders/SingleWorkOrder")
);
const Report = React.lazy(() => import("../../containers/Admin/Report"));

const EditEditor = React.lazy(() =>
  import("../../containers/Admin/Editor/EditEditor")
);

const Editjob = React.lazy(() => import("../../containers/Admin/Jobs/EditJob"));

const User = React.lazy(() => import("../../containers/User"));

const Editor = React.lazy(() => import("../../containers/Admin/Editor"));

const Employee = React.lazy(() => import("../../containers/Admin/Employee"));

const AddEmployee = React.lazy(() => import("Pages/Employee/AddEmployee"));

const EditEmployee = React.lazy(() => import("Pages/Employee/EditEmployee"));

export const adminMenus = [
  {
    path: "/",
    title: "Dashboard",
    insidebar: true,
    icon: <AntdIcons.DashboardOutlined />,
    component: Dashboard,
    name: "dashboard",
    type: ["ADMIN", "EDITOR","EMPLOYEE"],
  },
  {
    path: "/editors",
    title: "Editors",
    insidebar: true,
    icon: <AntdIcons.UserOutlined />,
    component: Editor,
    type: ["ADMIN"],
  },
  {
    path: "/editors/:id",
    title: "Edit Editor",
    insidebar: false,
    icon: <AntdIcons.UserOutlined />,
    component: EditEditor,
    name: "edit.editor",
    type: ["ADMIN"],
  },
  {
    path: "/employees",
    title: "Employees",
    insidebar: true,
    icon: <AntdIcons.UserOutlined />,
    component: Employee,
    name:"employee.index",
    type: ["ADMIN"],
  },
  {
    path: "/employees/add",
    title: "Add Employees",
    insidebar: false,
    icon: <AntdIcons.UserOutlined />,
    component: AddEmployee
    ,
    name:"employee.add",
    type: ["ADMIN"],
  },
  {
    path: "/employee/:id",
    title: "Edit Employee",
    insidebar: false,
    icon: <AntdIcons.UserOutlined />,
    component: EditEmployee,
    name: "edit.employee",
    type: ["ADMIN"],
  },
  {
    path: "/user/profile",
    title: "User Profile",
    insidebar: false,
    icon: <AntdIcons.UserOutlined />,
    component: User,
    name: "user.profile",
    type: ["ADMIN","EDITOR","EMPLOYEE"],
  },
  {
    path: "/editor/add",
    title: "Add Editor",
    insidebar: false,
    icon: <AntdIcons.UserOutlined />,
    component: AddEditor,
    type: ["ADMIN"],
  },

  {
    path: "/jobs",
    title: "Job Types",
    insidebar: true,
    icon: <AntdIcons.OrderedListOutlined />,
    component: Jobs,
    type: ["ADMIN"],
  },
  {
    path: "/job/:id",
    title: "Edit Job",
    insidebar: false,
    icon: <AntdIcons.OrderedListOutlined />,
    component: Editjob,
    name: "show.job",
    type: ["ADMIN"],
  },
  {
    path: "/create/job",
    title: "Add Job",
    insidebar: false,
    icon: <AntdIcons.AreaChartOutlined />,
    component: AddJobs,
    name: "add.job",
    type: ["ADMIN"],
  },
  {
    path: "/workorder/:slug",
    title: "Work Order Single",
    insidebar: false,
    icon: <AntdIcons.AreaChartOutlined />,
    component: SingleWorkOrder,
    name: "workorder.view",
    type: ["ADMIN","EMPLOYEE","EDITOR"],
  },

  {
    path: "/editor/stat/report",
    title: "Report",
    insidebar: true,
    icon: <AntdIcons.AreaChartOutlined />,
    component: Report,
    name: "workorder.view",
    type: ["ADMIN"],
  },

  {
    path: "/editor/stat/report",
    title: "Reporting",
    insidebar: true,
    icon: <AntdIcons.AreaChartOutlined />,
    component: Report,
    name: "workorder.view",
    type: ["EDITOR"],
  },
];
