import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import Logo from "../../assets/images/logo_black.png";
import { doLogin } from "../../utils/methods/universal";
import { useSetRecoilState } from "recoil";
import { isLogedIn, userInfoState } from "Atoms";

export default function Login() {
  const setisLogIn = useSetRecoilState(isLogedIn);
  const setUser = useSetRecoilState(userInfoState);

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [enableBtn, setEnableBtn] = useState(true);

  useEffect(() => {
    if (email !== "" && password !== "") {
      setEnableBtn(false);
    }

    if (email === "" || password === "") {
      setEnableBtn(true);
    }
  }, [email, password]);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    let response = await doLogin(email, password);

    //  setLogin(response.status);

    if (response.status) {
      setUser(response.user);
      setisLogIn(response.status);
      setLoading(false);
    } else {
      setisLogIn(response.status);
      setLoading(false);
    }
  }

  return (
    <div>
      <div className="row w-100 vh-100">
        <div className="col-md-4 m-auto">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center">
              <img
                src={Logo}
                alt="lacasatour"
                style={{ width: "100%", height: "100%" }}
              />
              
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="card shadow-lg">
                <div className="card-body p-5">
                  <h4>Login</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <Input
                        placeholder="Email address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <Input.Password
                        placeholder="Password"
                        name="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                      disabled={enableBtn}
                    >
                      Login
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
